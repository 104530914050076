import styled from '@emotion/styled';
import { spacing } from '../../../../../themes/stena-recycling/spacing';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: ${spacing.small};
`;

export const PaginationButtonWrapper = styled.div`
  display: flex;
  gap: ${spacing.xsmall};
`;

export const PageInfoWrapper = styled.div`
  display: flex;
  gap: ${spacing.xsmall};
  align-items: center;
`;
